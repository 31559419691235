import React from "react";

import machineImage from "../assets/contactUs.jpg";
import Address from "../components/CustomLogo/ContacUs/Address";
import Callus from "../components/CustomLogo/ContacUs/Callus";
import CompanyInformation from "../components/CustomLogo/ContacUs/CompanyInformation";
import MissionSection from "../components/contactus/MissionSection";

import {
  Building2,
  MapPin,
  Phone,
  Coffee,
  Sprout,
  Component,
  ArrowRight,
  Mail,
} from "lucide-react";

import ContactForm from "../components/contactus/ContactForm";

const ContactInfoItem = ({ title, content, Icon }) => {
  return (
    <div className="group flex flex-col items-center p-6">
      <div className="flex-shrink-0 mb-4">
        <Icon className="w-6 h-6 text-secondary" />
      </div>
      <div className="text-center space-y-2">
        <h3 className="text-lg text-primary uppercase tracking-wider">
          {title}
        </h3>
        <div className="text-sm text-primary">{content}</div>
      </div>
    </div>
  );
};

export default function ContactUs() {
  return (
    <div>
      <div className="pt-20">
        {/* Responsive height container */}
        <div className="h-[300px] md:h-[400px] bg-primary relative">
          {/* Stack on mobile, side by side on desktop */}
          <div className="flex flex-col md:flex-row h-full">
            {/* Text content */}
            <div className="flex-1 flex items-center justify-center p-4 md:p-8">
              <h1 className="text-3xl md:text-4xl font-bold text-white max-w-3xl">
                Building Tomorrow's Technology Systems Today.
              </h1>
            </div>

            {/* Image container */}
            <div className="w-full md:w-1/3 h-1/2 md:h-full relative overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-r from-primary/50 to-transparent z-10" />
              <img
                src={machineImage}
                alt="Industrial machine"
                className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-700"
              />
            </div>
          </div>
        </div>
      </div>

      <MissionSection />

      <div className="bg-secondary-background pb-6">
        <ContactForm />
      </div>

      <div className="w-full">
        <div className="max-w-7xl mx-auto py-20">
          {/* Section Header - Centered */}
          <div className="text-center mb-12 px-6">
            <div className="flex justify-center gap-3">
              <h1 className="text-3xl font-bold text-center text-secondary mb-2">
                Get in Touch
              </h1>
            </div>
            <h2 className="text-xl text-primary">
              Let's Connect and Create Something Amazing
            </h2>
          </div>

          {/* Contact Info Items - Horizontal Layout */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-6">
            <ContactInfoItem
              title="Visit Our Office"
              content={
                <div className="space-y-2">
                  <p className="font-semibold text-primary">
                    Minto Softech Private Limited
                  </p>
                  <div className="flex justify-center mb-2">
                    <Sprout className="w-5 h-5 text-primary" />
                  </div>
                  <p className="text-secondary">
                    Come see our office filled with Plants, Books, Charts,
                    Diecast Toy Cars and the Aroma of fresh Coffee!
                  </p>
                  <p className="text-sm text-primary mt-2">
                    Plot no. 114, at sy no 66/2 Street No. 03, floor no. 04,
                    <br />
                    Rai Durgam, Prashanth Hills Gachi Bowli,
                    <br />
                    Serilingampally, Hyderabad,
                    <br />
                    Telangana 500008
                  </p>
                </div>
              }
              Icon={MapPin}
            />

            <ContactInfoItem
              title="Let's Meet"
              content={
                <div className="space-y-2">
                  <div className="flex justify-center mb-2">
                    <Coffee className="w-5 h-5 text-primary" />
                  </div>
                  <p className="text-secondary">
                    Let's discuss over coffee! We know the best coffee shops in
                    town 😍
                  </p>
                  <div className="mt-4">
                    <p className="text-primary flex items-center justify-center gap-2">
                      <ArrowRight className="w-5 h-5" />
                      Connect with Ravi, Founder directly
                    </p>
                    <a
                      href="mailto:ravi@minto.ai"
                      className="flex items-center justify-center gap-2 mt-2 text-primary hover:underline"
                    >
                      <Mail className="w-5 h-5" />
                      ravi@minto.ai
                    </a>
                  </div>
                </div>
              }
              Icon={Coffee}
            />

            <ContactInfoItem
              title="Working Hours"
              content={
                <div className="space-y-2">
                  <p className="font-semibold text-primary">
                    Monday - Saturday
                  </p>
                  <p className="text-primary">9:00 AM - 7:00 PM IST</p>
                  <div className="flex justify-center mb-2">
                    <Component className="w-5 h-5 text-primary" />
                  </div>
                  <p className="text-sm text-secondary mt-2">
                    We're always excited to collaborate!
                  </p>
                </div>
              }
              Icon={Building2}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
