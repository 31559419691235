import React, { useState, useEffect, useRef } from "react";
import { ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from "framer-motion";
import { getCDNUrl } from "../utils/cdn";

const HomePageHeader = () => {
  const [revealedPills, setRevealedPills] = useState(0);
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });
  const videoRef = useRef(null);

  const content = {
    description: "Speak to Your Operations: Meet ",
    spiderAI: "spiderAI™",

    descriptionEnd: ", operational intelligence platform with autonomous gen-AI driven agents for machines &  equipments.",
    subContentStart: "Unify your health, maintenance and operational data of your machines & equipments , where ",
    subContentEnd: "think and act to unleash world class efficiencies in all the equipment related use cases.",
    AIAgents: "AI-Agents ",
    features: [
      "Autonomous Diagnostics",
      "Multi-agent architecture",
      "Controlled Ontologies and Knowledge Graphs",
      "Unified data fabric",
      "Machine Learning Integrated workflows",
      "Rapid integration of Knowledge"
    ]
  };

  useEffect(() => {
    const pillInterval = setInterval(() => {
      setRevealedPills((prev) => {
        if (prev < content.features.length) {
          return prev + 1;
        }
        clearInterval(pillInterval);
        return prev;
      });
    }, 500);

    return () => clearInterval(pillInterval);
  }, []);

  const handleVideoMetadata = () => {
    if (videoRef.current) {
      setVideoSize({
        width: videoRef.current.videoWidth,
        height: videoRef.current.videoHeight
      });
    }
  };

  return (
    <section className="w-full pt-20 sm:pt-24 md:pt-28 lg:pt-32 pb-6 sm:pb-6 md:pb-8 lg:pb-12 overflow-hidden">
      <div className="flex flex-col lg:flex-row items-start lg:items-center gap-8 lg:gap-12">
        {/* Left Column - Content */}
        <div className="w-full lg:w-1/2 flex flex-col space-y-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-4"
          >
            <p className="text-lg sm:text-xl lg:text-2xl font-bold text-primary">
              {content.description}
              <span className="text-secondary">{content.spiderAI}</span>
              {content.descriptionEnd}
            </p>
            <p className="text-base lg:text-lg text-primary">
              {content.subContentStart}
              <span className="text-secondary font-bold">{content.AIAgents}</span>
              {content.subContentEnd}
            </p>
          </motion.div>

          {/* Feature Pills */}
          <motion.div
            className="flex flex-wrap gap-3"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <AnimatePresence>
              {content.features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{
                    opacity: index < revealedPills ? 1 : 0,
                    scale: index < revealedPills ? 1 : 0.8,
                  }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <div className="px-4 py-2 bg-white  rounded-full shadow-md hover:shadow-lg transition-all duration-300">
                    <span className="text-sm lg:text-base font-semibold text-secondary">
                      {feature}
                    </span>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>

          {/* Compact CTA Button */}
          <div className="flex">
            <motion.button
              className="inline-flex items-center px-4 py-2 bg-secondary hover:bg-secondary/90 text-white text-sm font-bold shadow-md hover:shadow-lg transition-all duration-300 group"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <span>EXPLORE</span>
              <ChevronRight className="ml-1 h-4 w-4 transition-transform duration-300 group-hover:translate-x-1" />
            </motion.button>
          </div>
        </div>

        {/* Right Column - Video */}
        <div className="w-full lg:w-1/2 flex justify-center">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="relative max-w-full rounded-xl shadow-lg overflow-hidden"
          >
            <video
              ref={videoRef}
              src={`${getCDNUrl("/hot_saw_performance.mp4")}`}
              className="w-full h-auto"
              style={{
                maxHeight: '70vh',
                width: 'auto',
                maxWidth: '100%'
              }}
              onLoadedMetadata={handleVideoMetadata}
              autoPlay
              controls
              loop
              muted
              playsInline
              webkit-playsinline="true"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HomePageHeader;