import React, { useEffect, useState, useRef } from "react";
import { ChevronRight } from "lucide-react";
import postter from "../assets/video_image.jpg";
import { useNavigate } from "react-router-dom";
import { getCDNUrl } from "../utils/cdn";

const HomeHeroSection = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [videoError, setVideoError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const videoRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleVideoError = () => {
    console.error("Video failed to load");
    setVideoError(true);
  };

  const handleVideoLoad = () => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Video autoplay failed:", error);
        setVideoError(true);
      });
    }
  };

  return (
    <div className="relative h-screen md:h-[80vh] overflow-hidden flex flex-col justify-center">
      {/* Background Container */}
      <div
        className="absolute right-0 w-full h-[120%] overflow-hidden top-10 sm:top-20"
        style={{
          transform: `translateY(${offset * 0.5}px)`,
        }}
      >
        <video
          ref={videoRef}
          className="w-full h-full object-cover scale-x-[-1]"
          autoPlay
          muted
          loop
          playsInline
          onError={handleVideoError}
          onLoadedData={handleVideoLoad}
          poster={postter}
        >
          <source
            src="https://minto-webapp-v3.s3.us-east-2.amazonaws.com/video_iteration_1.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        {/* Enhanced Blue Gradient Overlay - Multiple Layers */}
        {/* Enhanced Blue Gradient Overlay - Multiple Layers */}
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900/60 via-primary/55 to-primary/65 mix-blend-multiply pointer-events-none" />
        <div className="absolute inset-0 bg-primary/20 pointer-events-none" />
        <div className="absolute inset-0 bg-gradient-to-t from-primary/15 via-transparent to-primary/15 pointer-events-none" />
      </div>

      {/* Hero Content */}
      <div className="relative z-10 container mx-auto px-4 lg:px-8 mt-8">
        <div ref={contentRef} className="max-w-2xl">
          <div className="animate-slide-up">
            <h1 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white mb-6 leading-relaxed">
              Your Machines's Full Potential Unleashed : One industrial IoT
              System, does it all !
            </h1>
          </div>

          <div className="animate-slide-up delay-200">
            <div className="bg-black/50 backdrop-blur-sm p-4 md:p-6 border-l-2 border-[#ff5757] max-w-xl mb-8">
              <p className="text-white text-base md:text-lg lg:text-xl">
                Run your{" "}
                <span className="inline-block mb-2">
                  <span className="border-b-2 border-secondary">machines</span>
                </span>{" "}
                &{" "}
                <span className="inline-block mb-2">
                  <span className="border-b-2 border-secondary">
                    equipments
                  </span>{" "}
                </span>{" "}
                to it`s{" "}
                <span className="inline-block mb-2">
                  <span className="border-b-2 border-secondary">
                    highest performance{" "}
                  </span>
                </span>
                ,{" "}
                <span className="inline-block mb-2">
                  <span className="border-b-2 border-secondary">
                    without breakdowns{" "}
                  </span>
                </span>{" "}
                while achieving{" "}
                <span className="inline-block mb-2">
                  <span className="border-b-2 border-secondary">
                    maximum efficiency{" "}
                  </span>
                </span>
                , all with <span className="text-[#ff5757]">iHz™(powered by spiderAI™)</span>
              </p>
            </div>
          </div>

          <div className="animate-slide-up delay-300">
            <button
              onClick={() => {
                navigate("./ihz");
              }}
              className="bg-secondary hover:bg-secondary/90 text-white px-6 md:px-8 py-3 flex items-center space-x-2 transition-all duration-300 transform hover:translate-x-2"
            >
              <span className="font-semibold">Know More</span>
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHeroSection;
